<template>
  <div v-if="params.data.isCredentialsInvalid">
    <feather-icon title="Invalid Credentials" icon="AlertTriangleIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
  </div>
</template>

<script>
export default {
  name: 'CellRendererCredentialValidity',
  computed: {

  },
  methods: {
  
  }
}
</script>
