<template>
  <div class="flex cell-renderer-checkbox-container">
    <vs-checkbox v-model="params.data.isActive" disabled />
  </div>
</template>

<script>
export default {
  name: 'CellRendererCheckbox'
}
</script>

<style scoped>
/* Make sure the checkbox centers vertically in the row*/
.cell-renderer-checkbox-container {
  height: inherit;
}
</style>